/* File: font.css */


@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Mono'), local('Roboto Mono'), url(../assets/fonts/roboto-mono/RobotoMono-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: bold;
    font-weight: 700;
    src: local('Roboto Mono'), local('Roboto Mono'), url(../assets/fonts/roboto-mono/RobotoMono-Bold.ttf) format('truetype');
}