@import "./fonts.css";
@import "./colors.scss";

#root {
  min-height: 100vh;
  position: relative;
}

a {
  color: $color-red !important;
  &:hover,
  &:active,
  &:focus {
    color: $color-dark-gray !important;
  }
}

button.btn,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  border-radius: 0;
  min-width: 100px;
  padding: 15px;
}

button.btn-danger {
  background-color: $color-red;
  border-color: $color-red;
}

button.btn-primary {
  background-color: $color-dark-gray;
  border-color: $color-dark-gray;

  &:hover,
  &:focus,
  &:active {
    background-color: black;
    border-color: black;
  }
}

.text-sm {
  font-size: 12px;
}

table.tracklist-table {
  tr {
   td:last-child {
    text-align: center;
   } 
  }
  
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.custom-control-label:before {
  background-color: red;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: black;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='red' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: green;
}

/** focus shadow pinkish **/
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 0, 247, 0.25);
}

#save-success {
  background-color: $color-middle-gray;
}

@media only screen and (max-width: 575px) {
  #logo,
  #user {
    justify-content: center;
    padding: 0;
  }

  #user {
    margin: 25px 0 10px;
  }

  .navbar > .container-fluid {
    padding: 0;
  }
}

@media only screen and (min-width: 576px) {
  #logo {
    justify-content: start;
  }
  #user {
    justify-content: end;
  }
}

@media only screen and (max-width: 767px) {
  .view-small {
    display: block;
  }

  .view-large {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .view-small {
    display: none;
  }

  .view-large {
    display: block;
  }
}

.container.view-small > .row > * {
  margin: 0.25em 0;
}

.table.table-bordered {
  th, td, tf {
    background-color: transparent;
  }
}

.form-select {
  max-width: 250px;
  max-height: 40px;
}

/* pagination */

.pagination {
  justify-content: space-between;

  > * {
    padding: 0!important;
  }

  #pagination-display {
    line-height: 2.5rem;
    text-align: center;
  }

  #pagination-select {
    text-align: right;

    > select {
      float: right;
    }
  }

  .btn:disabled {
    border: none;
    color: #aaa;
  }



  @media only screen and (max-width: 575px) {
    #pagination-buttons {
      justify-content: space-between;
      display: contents;
    }

    #pagination-display {
      padding: 25px;
    }

    #pagination-select {
      padding: 0;
      > select {
        max-width: 100%;
      }
    }
  }
}

  #create-new-list-button {
    position: absolute;
    top: 0px;
    right: 15px;
  }

  @media only screen and (max-width: 575px) {

    #create-new-list-button {
      right: 0px;
    }
  }

#footer {

  width: 100%;

  div {
    font-size: 12px;
    color: gray;
    padding: 5px;
  }
}

#login + #footer {
  position: absolute;
  bottom: 0;
}