body {
  margin: 0;
  font-family: "Roboto Mono" !important;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row {
  width: 100%;
  margin: 0 !important;
}

code {
  font-family: "Roboto Mono";
  font-weight: normal;
}

#logo svg {
  max-width: 100px;
  max-height: 50px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-bold input {
  font-weight: 500;
}
.border-dotted {
  border-style: dotted;
}

.form-floating label {
  opacity: 0.65;
}

.form-floating .form-control,
.form-floating .form-control-plaintext,
.form-floating .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating .form-control-plaintext:focus,
.form-floating .form-control-plaintext:not(:placeholder-shown),
.form-floating .form-control:focus,
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.date-form-floating label,
.form-floating .form-control-plaintext ~ label,
.form-floating .form-control:focus ~ label,
.form-floating .form-control:not(:placeholder-shown) ~ label,
.form-floating .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.spinner-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
}

.spinner {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 50%;
  right: 50%;
  margin-top: -40px;
  margin-right: -40px;
}

.react-datepicker-popper {
  z-index: 5!important;
}
/* .react-datepicker .react-datepicker__triangle {
  left: -50% !important;
} */

ul li.active {
  background-color: aliceblue;
}
